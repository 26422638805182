@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  /* background: #090f17ed; */
  /* background-image: linear-gradient(332deg, rgb(3, 48, 9) 0%, rgb(36, 25, 10) 31.25%, rgb(0, 9, 42) 100%);
  background: rgb(2,0,36);
  background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(1,25,17,1) 15%, rgba(27,1,2,1) 33%, rgba(16,3,34,1) 62%, rgba(0,7,32,1) 88%, rgba(0,6,8,1) 100%); */
  background-image: linear-gradient(to right top, #051937, #001b2c, #00191d, #00150e, #080e06);
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Use minimum height of viewport */
  height: 100vh; /* Force full height */
  width: 100%;
  padding: 0; /* Remove padding that could cause scrolling */
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #00011d1f;
  overflow: hidden; /* Prevent scrolling */
}

.app-header {
  text-align: center;
  background-color: #000e2c;
  color: white;
  width: 100%;
  flex-shrink: 0; /* Prevent header from shrinking */
}

.flex-grow {
  flex: 1 1 auto; /* grow, shrink, and auto basis */
  display: flex;
  flex-direction: column;
  height: 100%; /* Take full height of parent */
  overflow-y: auto; /* Allow scrolling within this container if needed */
  position: relative; /* For absolute positioning of children if needed */
}

#bg-img {
  width: 300px;
  height: 300px;
}

.signout {
  position: fixed;
  bottom: 5px;
  right: 8px;
  z-index: 1000; /* Ensure it stays on top */
}
.app-header {
  text-align: center;
  background-color: #000e2c;
  color: white;
  width: 100%;
}

h2 {
  margin-block: auto;
}

.room {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.room label {
  text-align: center;
  font-size: 25px;
  margin-bottom: 10px;
}

.room input {
  width: 160px;
  height: 35px;
  border: 2px solid #3b5998;
  border-radius: 6px;
  padding-left: 5px;
  font-size: 20px;
  text-align: center;
  margin: 5px;
  margin-bottom: 10px;
}

.room input::placeholder {
  font-size: 10px;
  position: relative;
  top: -0.5em;
}

.nickname-input {
  width: 160px;
  height: 30px;
  border: 2px solid #3b5998;
  color: black;
  border-radius: 6px;
  padding-left: 5px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 5px;
  margin-bottom: 10px;
}

.nickname-input::placeholder {
  font-size: 10px;
  position: relative;
  top: -0.5em;
}

.auth-button {
  width: 160px;
  height: 40px;
  border: none;
  border-radius: 6px;
  padding-left: 5px;
  font-size: 20px;
  text-align: center;
  margin: 5px;
  background-color: #4cefcce6;
  border: 1px solid #85f6ba;
  box-shadow: 0px 0px 6px 1px inset;
  color: rgb(18 3 61);
}

.auth-button-section {
  margin-top: 50px;
}

.timer-button {
  position: fixed;
  bottom: 10px;
  left: 14px;
}

.signout {
  position: fixed;
  bottom: 5px;
  right: 8px;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.w-full {
  width: 100%;
}

.timer-button-holder {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.bigText {
  font-size: 42px;
}

.score-animating {
  animation: scoreFlash 0.5s ease-out;
}


.tooltip {
  position: absolute;
  right: 5px;
  margin-top: -10px;
  padding: 4px 8px;
  background-color: #333;
  color: white;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.cursor-copy {
  cursor: copy;
}

.ox-inherit {
  overflow-x: inherit;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 767px) {
  .w-screen {
    width: 100vw;
  }

  .h-screen {
    height: 100vh;
  }
}