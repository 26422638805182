.dashboard-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: sans-serif;
  /*width: 90%;
  border-radius: 5px;*/
  margin: 0 auto;
  overflow: hidden;
  background: #090f1700;
  color: #ecfff6;
  width: inherit;
  height: inherit;
  overflow: scroll;
  overflow-x: clip;
  padding-bottom: 80px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.mainbuttons {
  position: absolute;
  top: 20px;
}

.mainbuttons {
  height: auto;
  max-height: calc(100vh - 120px); /* Adjust 120px based on your header height */
  max-height: calc(100dvh - 120px);
  /* Prevent text selection */
  -webkit-user-select: none;  /* Safari */
  -moz-user-select: none;     /* Firefox */
  -ms-user-select: none;      /* IE10+/Edge */
  user-select: none;          /* Standard */
  
  /* Prevent touch callouts/context menus on mobile */
  -webkit-touch-callout: none;
  
  /* Prevent drag operations */
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

/* Add height check media query */
@media screen and (min-height: 800px) {
  .dashboard-app {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }
  
  .mainbuttons {
    overflow: hidden;
  }
}

.text-xxs {
  font-size: 8px;
}

/* Minor responsiveness for now */
@media (max-width: 500px) {
  .mainbuttons {
    transform: translate(0px, -20px) scale(0.7);
  }
}
@media (max-width: 360px) {
  .mainbuttons {
    transform: translate(0px, -25px) scale(0.5);
  }
}

.header {
  /* background-color: #000e2c; */
  color: white;
  width: 100%;
  text-align: center;
  /* border-bottom: 1px solid #21c80b42; */
  /* padding-top: 20px; */
  height: 20px;
}

.game-over-header {
  margin-top: 80px;
  margin-bottom: 70px;
}

.top-a1 {
  position: absolute;

  font-size: 1.17em;
  font-weight: bold;
}

.mt-n1 {
  margin-top: -4px;
}

.pb-2 {
  padding-bottom: 20px;
}

.flex {
  display: flex;
}

.text-gray {
  color: gray;
}

.spell-label {
  font-size: 10px;
}

.pl-1 {
  padding-left: 1em;
}

.dragParent {
  align-content: center;
  width: 100%;
}

.dragParent > div {
  align-content: center;
  width: 200px;
  margin: auto;
}

.dragParent > div > div {
  border: 1px solid black;
}

.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.attack-and-defense {
  position: absolute;
  right: 20px;
  top: 100px;
  font-size: 20px;
}

.weather {
  text-align: right;
  margin-right: 8px;
  float: right;
  position: absolute;
  top: 16px;
  right: 16px;
}

.life-score {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.life-emoji {
  font-size: 33px;
  margin-top: 7px;
  margin-bottom: 10px;
}

.posRel {
  margin-left: auto;
  margin-right: auto;
}

.vertCenter {
  margin-top: auto;
  margin-bottom: auto;
}

.element-orders {
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: -10px;
  margin-bottom: 10px;
}

.element-orders div:nth-child(3) {
  margin-bottom: 70px;
}

.element-orders * {
  margin-top: auto;
  margin-bottom: auto;
}

.element-orders div {
  flex: 1;
  margin: 8px;
  cursor: move;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.element-orders span {
  width: 45px;
  font-size: 35px;
  background: #0a0f0c;
  border: 1px solid black;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 50%;
}

.neighborhood-buttons {
  /* margin: auto; */
  margin-top: 10px;
  margin-bottom: 10px;
  position: fixed;
  bottom: 10px;
  z-index: 102;
}

.neighborhood-buttons > span {
  margin-top: 10px;
  margin-right: 20px;
  vertical-align: middle;
  font-size: 23px;
  margin-left: 12px;
}

.attackable {
  cursor: pointer;
  border-radius: 25px;
  color: white;
  text-shadow: 2px 2px black;
  font-size: 20px;
  margin-right: 10px;
  margin-top: 8px;
  text-align: center;
}

.queued {
  cursor: default;
  opacity: 0.5;
}

.player-buttons {
  display: flex;
  margin-left: -10px;
}

.attack-span {
  margin-top: 10px;
  margin-bottom: -15px;
  margin-left: 10px;
  font-size: 10px;
}

.arena {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 720px) {
  .arena {
    flex-direction: column;
  }
  .neighborhood-buttons {
    display: flex;
  }
}

#arena {
   /*position: relative; Parent must be positioned */
  /* width: 300px; /* Set your desired dimensions */
  /* height: 300px; */
  /* border: 1px solid black; */
  /* overflow: hidden; Keeps circles within the arena */
}

.small-caps {
  font-variant: small-caps;
}

.circle {
    position: absolute;
    z-index: 100;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: moveCircle 2s forwards; /* Animation lasts 2 seconds */
    animation: moveCircle 2s forwards, wobble 1s infinite;
  }

.alootcircle {
  position: absolute;
  z-index: 100;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 0 10px 3px #00ff00; /* Green glow */
  animation: moveCircle 2s forwards; /* Animation lasts 2 seconds */
  animation: moveCircle 2s forwards, wobble 1s infinite;
}

.lootcircle {
  position: absolute;
  z-index: 100;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 0 10px 3px #00ff00; /* Green glow */
  animation: wobble 1s infinite;
}

.attackingcircle {
  position: absolute;
  z-index: 100;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: wobble 1s infinite;
}

@keyframes moveCircle {
    from {
        /* initial position set by JavaScript */
    }
    to {
        left: 50%; /* Moves to center of the arena */
        top: 50%;
        transform: translate(-50%, -50%); /* Centers the circle */
    }
}
  
@keyframes wobble {
  0%, 100% {
      border-radius: 50%;
  }
  20% {
      border-radius: 60% 40% 40% 70%;
  }
  30% {
    border-radius: 44% 50% 35% 60%;
  }
  50% {
      border-radius: 30% 70% 50% 50%;
  }
  70% {
      border-radius: 50% 60% 30% 44%;
  }
  80% {
      border-radius: 40% 60% 60% 40%;
  }
}
.element-buttons {
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 70%;
  flex-grow: 1;
  user-select: none;
  /*background: white;*/
}

.element-buttons div {
    flex: 1 1;
    justify-content: center;
    display: grid;
    align-content: center;
  }

  .element-buttons div.onRight {
    float: right;
    text-align: right;
  }

  .element-buttons div.onLeft {
    margin-top: 20px;
    float: left;
    text-align: left;
  }


.element-buttons button {
    width: 80px;
    height: 80px;
    font-size: 30px;
    vertical-align: middle;
    border-radius: 50%;
    box-shadow: inset 1px 1px 2px #245c8b;
    background: #1414271f;
    border: 1px solid #ffffff36;
    cursor: pointer;
  }

  .element-buttons button:disabled {
    cursor: not-allowed;
  }

h4 {
  margin-bottom: 5px;
}

.ml-1 {
  margin-left: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mt-3 {
  margin-top: 40px;
}

.mt-1 {
  margin-top: 5px;
}

.mb-1 {
  margin-bottom: 5px;
}

.mb-15 {
  margin-bottom: 10px;
}

.mb-100  {
  margin-bottom: 100px;
}

.bold {
  font-weight: bold;
}

.user {
  font-weight: bold;
  margin-right: 10px;
  /*color: #333;*/
}

.new-message-form {
  display: flex;
  width: 95%;
  padding: 15px;
  padding-top: 5px;
}

.new-message-input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
  color: #c2c2c2;
  padding: 10px;
  border-radius: 5px 0 0 5px;
}

*:disabled {
  cursor: not-allowed;
}

.send-button {
  border: none;
  outline: none;
  background: #000e2c;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  margin-right: 4px;
  margin-bottom: -6px;
  padding: 10px;
}

.new-nickname {
  z-index:4;
  top: 35px;
  position: absolute;
}

.new-nickname-input {
  width: 90px;
  outline: none;
  background: transparent;
  border: 1px dashed #7f7fbf;
  border-radius: 3px;
  margin-top: 2px;
  padding: 5px;
  padding-top: 0px;
  padding-left: 6px;
  margin-right: 3px;
  color: #c8e5ff;
  font-weight: 100;
  font-size: 12px;
  padding-top: 8px;
}

.active-player-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  margin-bottom: 1px;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
}

.z-100 {
  z-index: 100;
}

.text-center {
  text-align: center;
}

#trollbox {
  width: 100%;
  padding-top: 1em;
  padding-left: 1em;
  border-top: 1px dashed black;
}

.width-125 {
  width: 125px;
}

.leaderboard-header {
  font-size: 30px;
  border-bottom: 1px solid white;
  margin-bottom: 10px;
  text-align: center;
}

.signout-button {
  padding-left: 2px;
  padding-right: 1px;
  padding-bottom: 1px;
  border: 1px solid red;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 5px;
  transform: scale(0.8);
  color: red;
  opacity: 0.7;
  width: fit-content;
  cursor: pointer;
  z-index: 10000222 00;
}














.anyElement {
  align-items: center;
  align-content: center;
  text-align: center;
  margin-top:auto;
  display: grid;
  height: inherit;
  font-size: 30px;
  background-color: black;
  background-color: #06261f;
  border: 1px solid #ffffff0f;
  background-color: transparent;
  border: 1px groove #5cfff457;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  user-select: none;
}

.anyElement small{
  font-size: 10px;
  pointer-events: none;
  margin-bottom: 1px;
}

.anyElement strong{
  font-size: 15px;
  pointer-events: none;
}

.clickableButton {
  cursor: pointer;
}

.lifeInCenter {
    position: absolute;
    align-items: center;
    align-content: center;
    text-align: center;
    top: 300px;
    left: 225px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    z-index: 3;
    background-color: rgb(46, 54, 49, 14%);
}

.lifeInCenter strong {
  font-size: 15px;
}

.seed {
    position: absolute;
    top: 245px;
    left: 130px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
}
.fortify {
    position: absolute;
    top: 355px;
    left: 130px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
}
.empower {
    position: absolute;
    top: 245px;
    left: 325px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
}
.scry {
    position: absolute;
    top: 355px;
    left: 325px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
}
.air {
    position: absolute;
    top: 187px;
    left: 400px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: #f3f8ff73;
    border: 1px solid #f3f8ff73;
    background: transparent;
}
.fire {
    position: absolute;
    top: 412px;
    left: 400px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: #ba2807f5;
    border: 1px solid #ba2807f5;
    background: transparent;

}
.earth {
    position: absolute;
    top: 190px;
    left: 10px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: #332019;
    border: 1px solid #3f2b24;
    background: transparent;
}
.water {
    position: absolute;
    top: 412px;
    left: 10px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: #033c55eb;
    border: 1px solid #033c55eb;
    background: transparent;
}






@-webkit-keyframes breathing {
0% {
-moz-box-shadow: 0px 1px -1px #F00;
-webkit-box-shadow: 0px 1px -1px #F00;
box-shadow: 0px 1px -1px #F00;
}
50% {
-moz-box-shadow: 0px 1px 25px #F00;
-webkit-box-shadow: 0px 1px 25px #F00;
box-shadow: 0px 1px 25px #F00;
}
75% {
  -moz-box-shadow: 0px 1px 10px #F00;
  -webkit-box-shadow: 0px 1px 6px #F00;
  box-shadow: 0px 1px 12px #F00;
  }
}
@-moz-keyframes breathing {
0% {
-moz-box-shadow: 0px 1px 1px #F00;
-webkit-box-shadow: 0px 1px 1px #F00;
box-shadow: 0px 1px 1px #F00;
}
50% {
-moz-box-shadow: 0px 1px 25px #F00;
-webkit-box-shadow: 0px 1px 25px #F00;
box-shadow: 0px 1px 25px #F00;
}
}
@keyframes breathing {
0% {
-moz-box-shadow: 0px 1px 1px #F00;
-webkit-box-shadow: 0px 1px 1px #F00;
box-shadow: 0px 1px 1px #F00;
}
50% {
-moz-box-shadow: 0px 1px 25px #F00;
-webkit-box-shadow: 0px 1px 25px #F00;
box-shadow: 0px 1px 25px #F00;
}
}


@-webkit-keyframes breathingBlue {
0% {
-moz-box-shadow: 0px 1px -1px #1955f5;
-webkit-box-shadow: 0px 1px -1px #1955f5;
box-shadow: 0px 1px -1px #1955f5;
}
50% {
-moz-box-shadow: 0px 1px 25px #1955f5;
-webkit-box-shadow: 0px 1px 25px #1955f5;
box-shadow: 0px 1px 25px #1955f5;
}
}
@-moz-keyframes breathingBlue {
0% {
-moz-box-shadow: 0px 1px 1px #123082;
-webkit-box-shadow: 0px 1px 1px #143592;
box-shadow: 0px 1px 1px #0d2f8d;
}
50% {
-moz-box-shadow: 0px 1px 25px #132f7d;
-webkit-box-shadow: 0px 1px 25px #133389;
box-shadow: 0px 1px 25px #0c2f8f;
}
}
@keyframes breathingBlue {
0% {
-moz-box-shadow: 0px 1px 1px #1a3d9f;
-webkit-box-shadow: 0px 1px 1px #0f3291;
box-shadow: 0px 1px 1px #0c2f8e;
}
50% {
-moz-box-shadow: 0px 1px 25px #0f318c;
-webkit-box-shadow: 0px 1px 25px #143ba6;
box-shadow: 0px 1px 25px #0b287b;
}
}



@-webkit-keyframes breathingGray {
0% {
-moz-box-shadow: 0px 1px -1px #9ba6a9;
-webkit-box-shadow: 0px 1px -1px #9fadb0;
box-shadow: 0px 1px -1px #e5f7fc;
}
50% {
-moz-box-shadow: 0px 1px 25px #e5f7fc;
-webkit-box-shadow: 0px 1px 25px #e5f7fc;
box-shadow: 0px 1px 25px #e5f7fc;
}
}
@-moz-keyframes breathingGray {
0% {
-moz-box-shadow: 0px 1px 1px #e5f7fc;
-webkit-box-shadow: 0px 1px 1px #e5f7fc;
box-shadow: 0px 1px 1px #e5f7fc;
}
50% {
-moz-box-shadow: 0px 1px 25px #92a0a4;
-webkit-box-shadow: 0px 1px 25px #768184;
box-shadow: 0px 1px 25px #98a3a6;
}
}
@keyframes breathingGray {
0% {
-moz-box-shadow: 0px 1px 1px #8fa5ab;
-webkit-box-shadow: 0px 1px 1px #707a7d;
box-shadow: 0px 1px 1px #a3b0b4;
}
50% {
-moz-box-shadow: 0px 1px 25px #929ea1;
-webkit-box-shadow: 0px 1px 25px #889497;
box-shadow: 0px 1px 25px #6f787a;
}
}


@-webkit-keyframes breathingBrown {
0% {
-moz-box-shadow: 0px 1px -1px #593b0e;
-webkit-box-shadow: 0px 1px -1px #593b0e;
box-shadow: 0px 1px -1px #593b0e;
}
50% {
-moz-box-shadow: 0px 1px 25px #593b0e;
-webkit-box-shadow: 0px 1px 25px #593b0e;
box-shadow: 0px 1px 25px #593b0e;
}
}
@-moz-keyframes breathingBrown {
0% {
-moz-box-shadow: 0px 1px 1px #593b0e;
-webkit-box-shadow: 0px 1px 1px #593b0e;
box-shadow: 0px 1px 1px #593b0e;
}
50% {
-moz-box-shadow: 0px 1px 25px #593b0e;
-webkit-box-shadow: 0px 1px 25px #593b0e;
box-shadow: 0px 1px 25px #593b0e;
}
}
@keyframes breathingBrown {
0% {
-moz-box-shadow: 0px 1px 1px #593b0e;
-webkit-box-shadow: 0px 1px 1px #593b0e;
box-shadow: 0px 1px 1px #593b0e;
}
50% {
-moz-box-shadow: 0px 1px 25px #593b0e;
-webkit-box-shadow: 0px 1px 25px #593b0e;
box-shadow: 0px 1px 25px #593b0e;
}
}

.fireItem {
float: left;
margin-left: 20px;
-moz-box-shadow: 0px 1px 1px #F00;
-webkit-box-shadow: 0px 1px 1px #F00;
box-shadow: 0px 1px 1px #F00;
animation: breathing 3s linear infinite;
-ms-animation: breathing 3s linear infinite;
-webkit-animation: breathing 3s linear infinite;
-moz-animation: breathing 3s linear infinite;
-o-animation: breathing 3s linear infinite;
}
.fireItem h1 {
color: #666;
font-weight: 500;
padding-top: 45%;
text-align: center;
}

.waterItem {
float: left;
margin-left: 20px;
-moz-box-shadow: 0px 1px 1px #102f85;
-webkit-box-shadow: 0px 1px 1px #1955f5;
box-shadow: 0px 1px 1px #1955f5;
animation: breathingBlue 3s linear infinite;
-ms-animation: breathingBlue 3s linear infinite;
-webkit-animation: breathingBlue 3s linear infinite;
-moz-animation: breathingBlue 3s linear infinite;
-o-animation: breathingBlue 3s linear infinite;
}
.waterItem h1 {
color: #666;
font-weight: 500;
padding-top: 45%;
text-align: center;
}

.airItem {
  float: left;
  margin-left: 20px;
  -moz-box-shadow: 0px 1px 1px #e5f7fc;
  -webkit-box-shadow: 0px 1px 1px #e5f7fc;
  box-shadow: 0px 1px 1px #e5f7fc;
  animation: breathingGray 3s linear infinite;
  -ms-animation: breathingGray 3s linear infinite;
  -webkit-animation: breathingGray 3s linear infinite;
  -moz-animation: breathingGray 3s linear infinite;
  -o-animation: breathingGray 3s linear infinite;
}
.airItem h1 {
  color: #666;
  font-weight: 500;
  padding-top: 45%;
  text-align: center;
}


.earthItem {
  float: left;
  margin-left: 20px;
  -moz-box-shadow: 0px 1px 1px #1f1404;
  -webkit-box-shadow: 0px 1px 1px #1f1404;
  box-shadow: 0px 1px 1px #1f1404;
  animation: breathingBrown 3s linear infinite;
  -ms-animation: breathingBrown 3s linear infinite;
  -webkit-animation: breathingBrown 3s linear infinite;
  -moz-animation: breathingBrown 3s linear infinite;
  -o-animation: breathingBrown 3s linear infinite;
}
.earthItem h1 {
  color: #666;
  font-weight: 500;
  padding-top: 45%;
  text-align: center;
}

.middleColumn {
  display: flex;
  flex-direction: column;
  gap: 9px;
  position: absolute;
  top: 80px;
  left: 225px;
  border-radius: 50%;
  width: 100px;
  height: 100%;
}

.middleColumn div:nth-child(3) {
  margin-top: 115px;
}

.overflow-y-visible {
  overflow-y: visible;
}


.btn-4 {
  --clr: #6cd598d1;
  --outline: 1px solid #6cd598d1;
  color: var(--clr);
  outline: var(--outline);
  border: var(--outline);
  outline-offset: -1px;
  /*transition: outline-width 200ms ease, outline-offset 200ms ease;*/
  transition: box-shadow 300ms ease;
}

.btn-4:hover,
.btn-4:focus {
  /*outline: var(--outline);
  outline-width: 3px;
  outline-offset: -3px;
  filter: blur(2px);*/
  box-shadow: inset 0px 0px 2px 2px #6cd598d1;
}


.container {
  min-width: 600px;
  position: relative;
  margin-top: -75px;
  left: 20px;
  top: -30px;
  height: 620px;
  margin-bottom: 50px;
  margin: auto;
}

#canvas1 {
  position: absolute;
  top: 30px;
  left: -100px;
  z-index: 3;
  pointer-events: none;
}

#battle {
  margin: auto;
  /* display: grid;
  grid-template-columns: 10px 1fr 10px; */
}

.content {
  position: relative;
  width: 200px;
  height: 100px;
}

.content > div {
  position: absolute;
  width: 70px;
  height: 80px;
  /* transform-origin: 50% 50% 0px; */
  border-radius: 50%;
  color: white;
  line-height: 40px;
  padding-left: 35px;
  font-size: 33px;
  background: lightblue;
  text-transform: uppercase;
  letter-spacing: 2px;
  touch-action: none;
  user-select: none;
  cursor:move;
}

.content > div:nth-child(1) {
  background: linear-gradient(135deg, #f6d365 0%, #f7572a 100%);
}
.content > div:nth-child(2) {
  background: linear-gradient(135deg, #422a17 0%, #3b2b05 100%);

}
.content > div:nth-child(3) {
  background: linear-gradient(135deg, #5ee7df 0%, #b490ca 100%);
  margin-bottom: 70px;
}
.content > div:nth-child(4) {
  background: linear-gradient(135deg, #c3cfe2 0%, #c3cfe2 100%);
}

.glow {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 100px;
  left: 100px;
  border-radius: 50%;
  box-shadow: 0 0 150px #fff, 0 0 150px #fff;
}

.tiny {
  font-size: 8px;
  position: relative;
  bottom: 2px;
}

.z-101 {
  z-index: 101;
}

.settings-form {
  background-image: linear-gradient(to right top, #051937, #001b2c, #00191d, #00150e, #080e06);
  border: 1px solid #3b6247;
  border-style: solid;
  box-shadow: 1px 1px 5px #93ffed;
}

.rainbow {
  text-align: center;
  text-decoration: underline;
  font-size: 14px;
  line-height: 14px;
  vertical-align: top;
  letter-spacing: 2px;    font-variant: small-caps;

}

.rainbow_text_animated {
  background: linear-gradient(to right, #6666ff, #0099ff, #00ff00, #ff3399, #0099ff, #00ff00, #ff3399, #6666ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 2s linear infinite;
  background-size: 400% 100%;
}

@keyframes rainbow_animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}


.start-text {
  position: relative;
  bottom: 6px;
  right: 6px;
  user-select: none;
  font-variant: small-caps;
}