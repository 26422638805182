.auth {
    text-align: center;
}

.auth button {
    width: 200px;
    height: 50px;
    border: none;
    background-color: #000e2c;
    color: white;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
}

.clock-time:before {
    content: var(--timer-minutes-seconds,"03:00");
}
.center_text:before{
    position:absolute;
    top:3%;
    left:50%;
    transform: translate(-50% , 0%);
    z-index: 5;
}
.global_percent:before {
    content: var(--global_percent_val,"100%");
}
.local_percent:before {
    content: var(--local_percent_val,"0%");
}
.clock_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background:pink;
    width:50vmin;
    height:auto;
    aspect-ratio: 1 / 1.2 ;
    flex-direction: column;
    justify-content: flex-start ;
    margin-left:10vh;
    margin-top:10vh;
    color:white;
    font-size: 2.5vmin;
}
.timer {
    width: 100%;
    aspect-ratio: 1 / 1 ;
    background:red;
    display: flex;
    align-items: center;
    justify-content: center;
}
.timer_controls{
    width: 100%;
    flex-grow: 1;
    position: relative;
    display:flex;
    background:black;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index:2;
}
.player-control{
    position: relative;
    height:70%;
    aspect-ratio: 1 / 1 ;
    border:1vmin solid green;
    margin: auto 5%;
    border-radius: 50%;
}
.donat {
    aspect-ratio: 1 / 1 ;
    border-radius: 50%;
    border:1.5vmin solid transparent;
}
.outer-circle{
    position: relative;
    width:90%;
    background: linear-gradient(grey, grey) content-box no-repeat, conic-gradient(yellow 70%, 0, green ) border-box;
    z-index:0;
    display:flex;
    justify-content: center;
    align-items: center;
}
.inner-circle{
    position: relative;
    width:70%;
    z-index:1;
    background: linear-gradient(grey, grey) content-box no-repeat, conic-gradient(blue 25%, 0, green ) border-box;
    display:flex;
    justify-content: center;
}
.clock-timer{
    font-size: 10vh;
    color:purple;
}